@media screen and (min-width: 650px) {
  .app-bar {
    flex-direction: row;
  }



  .app-bar .app-bar__menu {
    display: none;
  }

  .app-bar .app-bar__navigation {
    display: flex;
    position: static;
    flex-direction: row;
    width: auto;
    background-color: transparent;
    box-shadow: none;
  }

  .app-bar .app-bar__navigation ul {
    display: flex;
    flex-direction: row;
  }

  .app-bar .app-bar__navigation ul li {
    position: relative;
  }

  .app-bar .app-bar__navigation ul li .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
    display: none;
    flex-direction: column;
    background-color: #66D1FF;
  }

  .app-bar .app-bar__navigation ul li:hover .dropdown-content {
    display: flex;
  }

  .app-bar .app-bar__navigation ul li .dropdown-content a {
    padding: 8px 16px;
    margin: 0;
  }
}

@media screen and (min-width: 800px) {
  .app-bar .app-bar__brand h1 {
    font-size: 2em;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-collapse {
    background-color: #333;
    text-align: center;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .app-bar {
    padding: 10px 100px;
  }
}

@media only screen and (max-width: 768px) {
  
  .imtikdesc{
    padding-inline: 6px !important;
  }

  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .content1 .img-fluid,
  .content4 .img-fluid {
    display: none;
  }

  .content1 {
    position: relative;
    background-image: url(../public/images/sosialisasi.jpeg);
    background-size: cover;
    background-position: center;
    height: 60vh;
    overflow: hidden;
    align-items: center;
    display: flex;
    min-height: 100vh;
  }

  .content1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .content1 > * {
    position: relative;
    z-index: 2;
  }

  .heading-title {
    text-align: center;
    line-height: 40px;
    margin-top: 20px;
  }
  .deskripsi {
    text-align: center;
  }
  .button-content1 {
    text-align: center;
  }

  .text-content2 {
    text-align: center;
  }
  .lead {
    font-size: 14px;
    padding-inline: 35px;
  }

  .content3 .card img {
    height: 150px !important;
  }
  .content3 .card-title {
    font-size: 16px;
  }
  .content3 .card-text {
    font-size: 12px;
  }
  .content4 {
    text-align: center;
  }
  .custom-carousel-image {
    padding: 45px;
  }
  .content6 .container {
    margin-top: 30px !important;
  }
  .kalkulator {
    height: 105vh !important;
  }
  .konsultasi .card-img-left {
    width: 135px !important;
    height: 290px !important;
  }

  .konsultasi .card-title {
    font-size: 1.2rem;
  }

  .konsultasi .card-text {
    font-size: 0.9rem;
    -webkit-line-clamp: 3;
  }
}

@media screen and (max-width: 550px) {
  .content5 .deskripsi {
    display: none;
  }
  .content5 .tester {
    width: 80% !important;
  }
  .content5 .btn {
    margin-top: 10px;
  }
  .content6 .container {
    margin-top: 0px !important;
  }
  .kalkulator {
    margin-top: 3rem;
  }
  .output #bmi {
    font-size: 2.5rem;
  }

  .bmi-scale h4 {
    font-size: 0.5rem;
  }

  .bmi-scale div {
    padding: 5px;
  }

  .bw{
    width: 125px !important;
  }

  .input-container{
    width: 10rem;
  }
  .quizg{
    width: 280px;
  }
  .output p,
  .output h3,
  .output #desc {
    font-size: 0.9rem;
  }
}
